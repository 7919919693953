export const contributorsData = [
  {
    image: 'img-vft-headshot-shawn-becky.jpg',
    name: `Shawn & Becky Feikema`,
    title: 'Farm Owners',
    copy: 'Our hosts, a husband and wife team, are family farmers and committed co-op members.',
  },
  {
    image: 'img-vft-headshot-maddie-smith.jpg',
    name: `Maddie Smith`,
    title: 'Ag Student',
    copy: 'Our student host is Minnesota’s 2021 National FFA Officer Candidate, and is currently a student at the University of Minnesota Twin Cities studying Agricultural Economics.',
  },
  {
    image: 'img-vft-headshot-jacob-bailey.jpg',
    name: `Jacob Bailey`,
    title: 'General Manager at CHS Farmers Alliance of Mitchell, South Dakota',
    copy: 'Learn how the 7 Cooperative Principles are used by co-ops to ensure members have a say.',
  },
  {
    image: 'img-vft-headshot-sydney-swanson.jpg',
    name: `Sydney Swanson`,
    title: 'Agronomist',
    copy: 'Find out how agronomists empower farmers through the amazing science of soil.',
  },
  {
    image: 'img-vft-headshot-les-klukas.jpg',
    name: `Les Klukas`,
    title: 'Energy Department Manager at CHS Brandon of Brandon, South Dakota',
    copy: 'Learn how energy sources like oil, diesel, and propane are essential to farms.',
  },
  {
    image: 'img-vft-headshot-kent-mulder.jpg',
    name: `Kent Mulder`,
    title: 'General Manager at CHS Brandon of Brandon, South Dakota',
    copy: 'Keeping the success of farmers in mind and empowering cooperative employees is a key goal of this co-op leader.',
  },
  {
    image: 'img-vft-headshot-pete-penkert.jpg',
    name: `Pete Penkert`,
    title:
      'Assistant Refinery Production Manager at CHS  Processing Plant in Mankato, Minnesota',
    copy: 'Discover which 100% renewable crop is used to make everything from food to fuel products.',
  },
];
