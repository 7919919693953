import React from 'react';
import './styles.scss';
import Image from '../Image';
import { Row, Column } from '../../components/Grid';
import { contributorsData } from '../../data/contributors/contributorsData';

const Contributors = () => {
  return (
    <>
      <Row>
        {contributorsData.map(({ image, name, title, copy }) => (
          <Column key={name} medium={4} large={3}>
            <div className="contributor">
              <Image filename={image} />
              <p className="contributor__name">{name}</p>
              <p className="contributor__title">{title}</p>
              <p className="contributor__copy">{copy}</p>
            </div>
          </Column>
        ))}
      </Row>
    </>
  );
};

export default Contributors;
